var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"commerceOder"}},[_c('div',{staticClass:"main-list"},[_c('pagination2',{ref:"childDialogBox",staticClass:"childDialogBox",attrs:{"option":_vm.post,"url":"/LiveGoods/goodsOrderList"},scopedSlots:_vm._u([{key:"default",fn:function({ tableData: { list } }){return [_c('div',{staticClass:"top"},[_c('el-row',{staticClass:"row"},[_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"grid-content bg-purple"},[_vm._v(" 商品名称："+_vm._s(_vm.ruleForm.name)+" ")])]),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"grid-content bg-purple-light"},[_vm._v(" 类型："+_vm._s(_vm._f("gettype2")(_vm.ruleForm.goods_class))+" ")])]),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"grid-content bg-purple"},[_vm._v(" 已售："+_vm._s(_vm.ruleForm.sale_num)+" ")])])],1),_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"grid-content bg-purple"},[_vm._v(" 当前售价：￥"+_vm._s(_vm.ruleForm.price)+" ")])])],1)],1),_c('div',{staticClass:"search"},[_c('el-input',{staticClass:"input-with-select",staticStyle:{"width":"338px"},attrs:{"size":"medium","placeholder":_vm.post.search_type == 1 ? '输入学员名称搜索' : '输入订单搜索'},model:{value:(_vm.post.search),callback:function ($$v) {_vm.$set(_vm.post, "search", $$v)},expression:"post.search"}},[_c('el-select',{staticStyle:{"width":"120px"},attrs:{"slot":"prepend","placeholder":"请选择"},slot:"prepend",model:{value:(_vm.post.search_type),callback:function ($$v) {_vm.$set(_vm.post, "search_type", $$v)},expression:"post.search_type"}},[_c('el-option',{attrs:{"label":"学员","value":"1"}}),_c('el-option',{attrs:{"label":"订单","value":"2"}})],1)],1)],1),_c('el-table',{staticClass:"table-class",attrs:{"header-cell-style":{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          },"data":list}},[_c('el-table-column',{attrs:{"label":"学员名称"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"tu_name"},[_c('div',{staticClass:"tu"},[_c('img',{attrs:{"src":row.uphoto,"alt":""}})]),_c('div',{staticClass:"name",attrs:{"title":row.uname}},[_vm._v(_vm._s(row.uname))])])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"订单号","prop":"number"}}),_c('el-table-column',{attrs:{"label":"付款时间"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("formatTimeStamp")(row.order_time,'yyyy-MM-dd hh:mm:ss'))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"出售价(元)"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',{staticClass:"redcolor"},[_vm._v("￥"+_vm._s(row.price))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"实付(元)"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',{staticClass:"redcolor"},[_vm._v("￥"+_vm._s(row.price))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.status == 1)?_c('span',{staticClass:"redcolor"},[_vm._v("未支付")]):_c('span',{staticStyle:{"color":"#29ae48"}},[_vm._v("支付成功")])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$router.push({
                    path:
                      '/capitalOrder/cashcouponDetail/' +
                      row.live_goods_order_id,
                  })}}},[_vm._v(" 订单详情 ")])]}}],null,true)})],1)]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }