<template>
  <div id="commerceOder">
    <div class="main-list">
      <pagination2
        class="childDialogBox"
        :option="post"
        url="/LiveGoods/goodsOrderList"
        ref="childDialogBox"
      >
        <template v-slot:default="{ tableData: { list } }">
          <div class="top">
            <el-row class="row">
              <el-col :span="8">
                <div class="grid-content bg-purple">
                  商品名称：{{ ruleForm.name }}
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content bg-purple-light">
                  类型：{{ ruleForm.goods_class | gettype2 }}
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content bg-purple">
                  已售：{{ ruleForm.sale_num }}
                </div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <div class="grid-content bg-purple">
                  当前售价：￥{{ ruleForm.price }}
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="search">
            <el-input
              style="width: 338px"
              size="medium"
              :placeholder="
                post.search_type == 1 ? '输入学员名称搜索' : '输入订单搜索'
              "
              v-model="post.search"
              class="input-with-select"
            >
              <el-select
                style="width: 120px"
                v-model="post.search_type"
                slot="prepend"
                placeholder="请选择"
              >
                <el-option label="学员" value="1"></el-option>
                <el-option label="订单" value="2"></el-option>
              </el-select>
            </el-input>
          </div>

          <el-table
            class="table-class" 
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            :data="list"
          >
            <el-table-column label="学员名称">
              <template slot-scope="{ row }">
                <div class="tu_name">
                  <div class="tu">
                    <img :src="row.uphoto" alt="" />
                  </div>
                  <div class="name" :title="row.uname">{{ row.uname }}</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="订单号" prop="number"></el-table-column>

            <el-table-column label="付款时间">
              <template slot-scope="{ row }">
                {{ row.order_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
              </template>
            </el-table-column>

            <el-table-column label="出售价(元)">
              <template slot-scope="{ row }">
                <span class="redcolor">￥{{ row.price }}</span>
              </template>
            </el-table-column>

            <el-table-column label="实付(元)">
              <template slot-scope="{ row }">
                <span class="redcolor">￥{{ row.price }}</span>
              </template>
            </el-table-column>

            <el-table-column label="状态">
              <template slot-scope="{ row }">
                <span class="redcolor" v-if="row.status == 1">未支付</span>
                <span style="color: #29ae48" v-else>支付成功</span>
              </template>
            </el-table-column>

            <el-table-column label="操作">
              <template slot-scope="{ row }">
                <el-button
                  type="text"
                  @click="
                    $router.push({
                      path:
                        '/capitalOrder/cashcouponDetail/' +
                        row.live_goods_order_id,
                    })
                  "
                >
                  订单详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commerceOder',
  data() {
    return {
      ruleForm: {},

      post: {
        live_goods_id: this.$route.query.id,
        search_type: '1',
        search: '',
      },
    }
  },

  created() {
    this.edit()
  },

  filters: {
    gettype2(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '知识商品'
          break
        case 2:
          text = '外部商品'
          break
      }
      return text
    },
  },

  methods: {
    edit() {
      this.$http({
        url: `/LiveGoods/setGoods?live_goods_id=${this.$route.query.id}`,
        callback: ({ data }) => {
          this.ruleForm = data
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .pagination {
  background-color: #fff;
}
::v-deep .input-with-select {
  .el-input__inner {
    color: #333333;
  }
  .el-input-group__prepend {
    background-color: #fff;
  }
}
#commerceOder {
  .top {
    padding: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    .row {
      margin-bottom: 23px;
    }
    .grid-content {
      font-size: 12px;
      color: #333333;
    }
  }
  .main-list {
    .search {
      padding: 20px;
      background-color: #fff;
      text-align: right;
    }
    .childDialogBox {
      margin-top: 20px;

      .table-class {
        padding: 0 20px;
        background-color: #fff;
        .tu_name {
          display: flex;
          align-items: center;
          .tu {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            img {
              height: 100%;
              width: 100%;
            }
          }
          .name {
            width: calc(100% - 40px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>